import constants from "@/api";

const initialState = () => ({
  addressList: [],
  employeesList: [],
  statesList: [],
  showMergeModal: false,
  dataForMoveFrom: null,
  dataForMoveTo: null,
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    failure: (s, msg) => {
      console.log("[failure] ", msg);
    },
    resetState(state) {
      const initial = initialState();
      Object.keys(initial).forEach((key) => {
        state[key] = initial[key];
      });
    },
    setAddressList(state, data) {
      state.addressList = data;
    },
    setEmployeeList(state, data) {
      state.employeesList = data;
    },
    setStateList(state, data) {
      state.statesList = data;
    },
    setDataForMoveTo(state, data) {
      state.dataForMoveTo = data;
    },
    openMergeModal(state, data) {
      if (data) {
        state.dataForMoveFrom = data;
        state.showMergeModal = true;
      }
    },
    closeMergeModal(state) {
      state.showMergeModal = false;
      state.dataForMoveFrom = null;
      state.dataForMoveTo = null;
    },
  },
  actions: {
    getChangelogsList: ({ commit, dispatch }, payload) => {
      let fail = (msg) => commit("failure", msg);
      return dispatch(
        "apiCall",
        {
          method: "get",
          params: payload,
          url: constants.CHANGELOGS,
        },
        { root: true }
      )
        .then((data) => {
          if (data.ok) {
            return {
              ok: true,
              list: data.data,
              fetchCount: data.fetchCount,
              totalCount: data.totalCount,
            };
          } else {
            fail(data.message || "Failed to load ChangeLogs List");
            return {
              ok: false,
              list: [],
            };
          }
        })
        .catch((err) => {
          console.log("Yo ", err);
          fail(err.toString() || "Failed to load ChangeLogs List");
          return { ok: false, totalCount: 0, fetchCount: 0, list: [] };
        });
    },
    getStatesList: ({ commit, dispatch }, payload) => {
      let fail = (msg) => commit("failure", msg);
      return dispatch(
        "apiCall",
        {
          method: "get",
          params: payload,
          url: constants.ALL_STATES,
        },
        { root: true }
      )
        .then((data) => {
          if (data.ok) {
            commit("setStateList", data.data);
            return {
              ok: true,
              list: data.data,
              fetchCount: data.fetchCount,
              totalCount: data.totalCount,
            };
          } else {
            fail(data.message || "Failed to load States List");
            return {
              ok: false,
              list: [],
            };
          }
        })
        .catch((err) => {
          console.log("Yo ", err);
          fail(err.toString() || "Failed to load States List");
          return { ok: false, totalCount: 0, fetchCount: 0, list: [] };
        });
    },
    getAgentCitiesList: ({ commit, dispatch }, payload) => {
      let fail = (msg) => commit("failure", msg);
      return dispatch(
        "apiCall",
        {
          method: "get",
          params: payload,
          url: constants.AGENT_CITIES,
        },
        { root: true }
      )
        .then((data) => {
          if (data.ok) {
            return {
              ok: true,
              list: data.data,
              fetchCount: data.fetchCount,
              totalCount: data.totalCount,
            };
          } else {
            fail(data.message || "Failed to get Agent Cities List");
            return {
              ok: false,
              list: [],
              fetchCount: 0,
              totalCount: 0,
            };
          }
        })
        .catch((err) => {
          console.log("Yo ", err);
          fail(err.toString() || "Failed to get Agent Cities List");
          return { ok: false, totalCount: 0, fetchCount: 0, list: [] };
        });
    },
    getAgentEmployeeInformation: ({ commit, dispatch }, payload) => {
      let fail = (msg) => commit("failure", msg);
      return dispatch(
        "apiCall",
        {
          method: "get",
          params: payload,
          url: constants.AGENT_EMPLOYEE_DETAILS,
        },
        { root: true }
      )
        .then((data) => {
          if (data.ok) {
            return {
              ok: true,
              list: data.data,
              fetchCount: data.fetchCount,
              totalCount: data.totalCount,
            };
          } else {
            fail(
              data.message || "Failed to get Agent Employee Information List"
            );
            return {
              ok: false,
              list: [],
              fetchCount: 0,
              totalCount: 0,
            };
          }
        })
        .catch((err) => {
          console.log("Yo ", err);
          fail(
            err.toString() || "Failed to get Agent Employee Information List"
          );
          return { ok: false, totalCount: 0, fetchCount: 0, list: [] };
        });
    },

    getUploadLogs: ({ commit, dispatch }, payload) => {
      let fail = (msg) => commit("failure", msg);
      return dispatch(
        "apiCall",
        {
          method: "get",
          params: payload,
          url: constants.BULK_UPLOAD_LOGS,
        },
        { root: true }
      )
        .then((data) => {
          if (data.ok) {
            return {
              ok: true,
              list: data.data,
              fetchCount: data.fetchCount,
              totalCount: data.totalCount,
            };
          } else {
            fail(data.message || "Failed to load upload Logs List");
            return {
              ok: false,
              totalCount: 0,
              fetchCount: 0,
              list: [],
            };
          }
        })
        .catch((err) => {
          console.log("Yo ", err);
          fail(err.toString() || "Failed to load upload Logs List");
          return { ok: false, totalCount: 0, fetchCount: 0, list: [] };
        });
    },
    getInputFileURL: ({ commit, dispatch }, payload) => {
      let fail = (msg) => commit("failure", msg);
      return dispatch(
        "apiCall",
        {
          method: "get",
          params: payload,
          url: constants.BULK_UPLOAD_INPUT_FILE,
        },
        { root: true }
      )
        .then((data) => {
          if (data.ok) {
            return {
              ok: true,
              url: data.url,
            };
          } else {
            fail(data.message || "Failed to load upload Logs List");
            return {
              ok: false,
              url: "",
            };
          }
        })
        .catch((err) => {
          console.log("Yo ", err);
          fail(err.toString() || "Failed to load upload Logs List");
          return { ok: false, url: "" };
        });
    },

    uploadTravelAgents: ({ commit, dispatch }, payload) => {
      let fail = (msg) => commit("failure", msg);
      return dispatch(
        "apiCallWithHeaderConfig",
        {
          partConfig: {
            method: "post",
            data: payload,
            url: constants.UPLOAD_TRAVEL_AGENT_FILE,
          },
          headerConfig: {
            "Content-Type": "multipart/form-data",
          },
        },
        { root: true }
      )
        .then((data) => {
          if (!data.ok)
            fail(data.message || "Failed to upload travel agent file");
          return data;
        })
        .catch((err) => {
          fail(err.toString() || "Failed to upload travel agent file");
          return {
            ok: false,
            message: err.message,
          };
        });
    },
    spawnProcess: ({ commit, dispatch }) => {
      let fail = (msg) => commit("failure", msg);
      return dispatch(
        "apiCall",
        {
          method: "get",
          data: {},
          url: constants.SPAWN_PROCESS,
        },
        { root: true }
      )
        .then((data) => {
          if (data.ok) {
            return data;
          } else {
            fail(data.message || "Failed spawn process");
            return { ok: false };
          }
        })
        .catch((err) => {
          console.log("Yo ", err);
          fail(err.toString() || "Failed spawn process");
          return { ok: false };
        });
    },
    deleteSpawnProcess: ({ commit, dispatch }, payload) => {
      let fail = (msg) => commit("failure", msg);
      return dispatch(
        "apiCall",
        {
          method: "post",
          data: payload,
          url: constants.BULK_UPLOAD_DELETE_SPAWN,
        },
        { root: true }
      )
        .then((data) => {
          if (!data.ok) fail(data.message || "Failed to delete Spawn Process");
          return data;
        })
        .catch((err) => {
          fail(err.toString() || "Failed to delete Spawn Process");
          return {
            ok: false,
            message: err.message,
          };
        });
    },
    downloadErrorFile: ({ commit, dispatch }, payload) => {
      let fail = (msg) => commit("failure", msg);
      return dispatch(
        "fileDownload_API_Call",
        {
          method: "get",
          params: {},
          params: payload,
          url: constants.BULK_UPLOAD_ERROR_FILE,
          responseType: "blob",
        },
        { root: true }
      )
        .then(({ data, response }) => {
          if (response.status === 200) {
            commit(
              "openSnackbar",
              { text: "Starting Download" },
              { root: true }
            );
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Errors.xlsx"); //or any other extension
            document.body.appendChild(link);
            link.click();
            return;
          } else {
            commit(
              "openSnackbar",
              { text: "Could not start download" },
              { root: true }
            );
            fail(data.message || "Failed to start download");
            return;
          }
        })
        .catch((err) => {
          console.log("Yo ", err);
          commit(
            "openSnackbar",
            { text: "Could not start download" },
            { root: true }
          );
          fail(err.toString() || "Failed to Download Core Data File");
        });
    },

    getCompaniesList: ({ commit, dispatch }, payload) => {
      let fail = (msg) => commit("failure", msg);
      return dispatch(
        "apiCall",
        {
          method: "get",
          params: payload,
          url: constants.TRAVEL_AGENT,
        },
        { root: true }
      )
        .then((data) => {
          if (data.ok) {
            return {
              ok: true,
              totalCount: data.totalCount,
              fetchCount: data.fetchCount,
              list: data.data,
            };
          } else {
            fail(data.message || "Failed to load Target Agent List");
            return {
              ok: false,
              totalCount: data.totalCount,
              fetchCount: 0,
              list: [],
            };
          }
        })
        .catch((err) => {
          console.log("Yo ", err);
          fail(err.toString() || "Failed to load Target Agent List");
          return { ok: false, totalCount: 0, fetchCount: 0, list: [] };
        });
    },
    addCompany: ({ commit, dispatch }, payload) => {
      let fail = (msg) => commit("failure", msg);
      return dispatch(
        "apiCall",
        {
          method: "post",
          data: payload,
          url: constants.TRAVEL_AGENT,
        },
        { root: true }
      )
        .then((data) => {
          if (!data.ok) fail(data.message || "Failed to add Target Agent");
          return data;
        })
        .catch((err) => {
          fail(err.toString() || "Failed to add Target Agent");
          return {
            ok: false,
            message: err.message,
          };
        });
    },
    editCompany: ({ commit, dispatch }, payload) => {
      let fail = (msg) => commit("failure", msg);
      return dispatch(
        "apiCall",
        {
          method: "put",
          data: payload,
          url: constants.TRAVEL_AGENT,
        },
        { root: true }
      )
        .then((data) => {
          if (!data.ok) fail(data.message || "Failed to edit Target Agent");
          return data;
        })
        .catch((err) => {
          fail(err.toString() || "Failed to edit Target Agent");
          return {
            ok: false,
            message: err.message,
          };
        });
    },

    getAddressList: ({ commit, dispatch }, payload) => {
      let fail = (msg) => commit("failure", msg);
      return dispatch(
        "apiCall",
        {
          method: "get",
          params: payload,
          url: constants.TRAVEL_AGENT_ADDRESS,
        },
        { root: true }
      )
        .then((data) => {
          if (data.ok) {
            return {
              ok: true,
              totalCount: data.totalCount,
              fetchCount: data.fetchCount,
              list: data.data,
            };
          } else {
            fail(data.message || "Failed to Load Address List");
            return {
              ok: false,
              totalCount: data.totalCount,
              fetchCount: 0,
              list: [],
            };
          }
        })
        .catch((err) => {
          console.log("Yo ", err);
          fail(err.toString() || "Failed to Load Address List");
          return { ok: false, totalCount: 0, fetchCount: 0, list: [] };
        });
    },
    addAddress: ({ commit, dispatch }, payload) => {
      let fail = (msg) => commit("failure", msg);
      return dispatch(
        "apiCall",
        {
          method: "post",
          data: payload,
          url: constants.TRAVEL_AGENT_ADDRESS,
        },
        { root: true }
      )
        .then((data) => {
          if (!data.ok) fail(data.message || "Failed to add Address");
          return data;
        })
        .catch((err) => {
          fail(err.toString() || "Failed to add Address");
          return {
            ok: false,
            message: err.message,
          };
        });
    },
    editAddress: ({ commit, dispatch }, payload) => {
      let fail = (msg) => commit("failure", msg);
      return dispatch(
        "apiCall",
        {
          method: "put",
          data: payload,
          url: constants.TRAVEL_AGENT_ADDRESS,
        },
        { root: true }
      )
        .then((data) => {
          if (!data.ok) fail(data.message || "Failed to edit Address");
          return data;
        })
        .catch((err) => {
          fail(err.toString() || "Failed to edit Address");
          return {
            ok: false,
            message: err.message,
          };
        });
    },

    checkEmployeeDetail: ({ commit, dispatch }, payload) => {
      let fail = (msg) => commit("failure", msg);
      return dispatch(
        "apiCall",
        {
          method: "get",
          params: payload,
          url: constants.DUPLICATE_DATA_WARNING,
        },
        { root: true }
      )
        .then((data) => {
          if (data.ok) {
            return {
              ok: true,
              data: data.data,
            };
          } else {
            fail(data.message || "Failed to get Employee Details");
            return {
              ok: false,
              data: {},
            };
          }
        })
        .catch((err) => {
          console.log("Yo ", err);
          fail(err.toString() || "Failed to get Employee Details");
          return { ok: false, data: {} };
        });
    },
    getCompanyEmployeeList: ({ commit, dispatch }, payload) => {
      let fail = (msg) => commit("failure", msg);
      return dispatch(
        "apiCall",
        {
          method: "get",
          params: payload,
          url: constants.TRAVEL_AGENT_EMPLOYEES,
        },
        { root: true }
      )
        .then((data) => {
          if (data.ok) {
            return {
              ok: true,
              totalCount: data.totalCount,
              fetchCount: data.fetchCount,
              list: data.data,
            };
          } else {
            fail(data.message || "Failed to Load Travel Agent Employee List");
            return {
              ok: false,
              totalCount: data.totalCount,
              fetchCount: 0,
              list: [],
            };
          }
        })
        .catch((err) => {
          console.log("Yo ", err);
          fail(err.toString() || "Failed to Load Travel Agent Employee List");
          return { ok: false, totalCount: 0, fetchCount: 0, list: [] };
        });
    },
    addCompanyEmployee: ({ commit, dispatch }, payload) => {
      let fail = (msg) => commit("failure", msg);
      return dispatch(
        "apiCall",
        {
          method: "post",
          data: payload,
          url: constants.TRAVEL_AGENT_EMPLOYEES,
        },
        { root: true }
      )
        .then((data) => {
          if (!data.ok)
            fail(data.message || "Failed to add Travel Agent Employee");
          return data;
        })
        .catch((err) => {
          fail(err.toString() || "Failed to add Travel Agent Employee");
          return {
            ok: false,
            message: err.message,
          };
        });
    },
    editCompanyEmployee: ({ commit, dispatch }, payload) => {
      let fail = (msg) => commit("failure", msg);
      return dispatch(
        "apiCall",
        {
          method: "put",
          data: payload,
          url: constants.TRAVEL_AGENT_EMPLOYEES,
        },
        { root: true }
      )
        .then((data) => {
          if (!data.ok)
            fail(data.message || "Failed to edit Travel Agent Employee");
          return data;
        })
        .catch((err) => {
          fail(err.toString() || "Failed to edit Travel Agent Employee");
          return {
            ok: false,
            message: err.message,
          };
        });
    },

    getFamTripList: ({ commit, dispatch }, payload) => {
      let fail = (msg) => commit("failure", msg);
      return dispatch(
        "apiCall",
        {
          method: "get",
          params: payload,
          url: constants.FAM_TRIP,
        },
        { root: true }
      )
        .then((data) => {
          if (data.ok) {
            return {
              ok: true,
              totalCount: data.totalCount,
              fetchCount: data.fetchCount,
              list: data.data,
            };
          } else {
            fail(data.message || "Failed to Load Travel Agent Employee List");
            return {
              ok: false,
              totalCount: data.totalCount,
              fetchCount: 0,
              list: [],
            };
          }
        })
        .catch((err) => {
          console.log("Yo ", err);
          fail(err.toString() || "Failed to Load Travel Agent Employee List");
          return { ok: false, totalCount: 0, fetchCount: 0, list: [] };
        });
    },
    addFamTrip: ({ commit, dispatch }, payload) => {
      let fail = (msg) => commit("failure", msg);
      return dispatch(
        "apiCall",
        {
          method: "post",
          data: payload,
          url: constants.FAM_TRIP,
        },
        { root: true }
      )
        .then((data) => {
          if (!data.ok)
            fail(data.message || "Failed to add Travel Agent Employee");
          return data;
        })
        .catch((err) => {
          fail(err.toString() || "Failed to add Travel Agent Employee");
          return {
            ok: false,
            message: err.message,
          };
        });
    },
    editFamTrip: ({ commit, dispatch }, payload) => {
      let fail = (msg) => commit("failure", msg);
      return dispatch(
        "apiCall",
        {
          method: "put",
          data: payload,
          url: constants.FAM_TRIP,
        },
        { root: true }
      )
        .then((data) => {
          if (!data.ok)
            fail(data.message || "Failed to edit Travel Agent Employee");
          return data;
        })
        .catch((err) => {
          fail(err.toString() || "Failed to edit Travel Agent Employee");
          return {
            ok: false,
            message: err.message,
          };
        });
    },
    deleteFamTrip: ({ commit, dispatch }, payload) => {
      let fail = (msg) => commit("failure", msg);
      return dispatch(
        "apiCall",
        {
          method: "delete",
          data: payload,
          url: constants.FAM_TRIP,
        },
        { root: true }
      )
        .then((data) => {
          if (!data.ok) fail(data.message || "Failed to Delete Fam Trip");
          return data;
        })
        .catch((err) => {
          fail(err.toString() || "Failed to Delete Fam Trip");
          return {
            ok: false,
            message: err.message,
          };
        });
    },
    deleteTravelAgent: ({ commit, dispatch }, payload) => {
      let fail = (msg) => commit("failure", msg);
      return dispatch(
        "apiCall",
        {
          method: "delete",
          data: payload,
          url: constants.TRAVEL_AGENT,
        },
        { root: true }
      )
        .then((data) => {
          if (!data.ok) fail(data.message || "Failed to Delete Travel Agent.");
          return data;
        })
        .catch((err) => {
          fail(err.toString() || "Failed to Delete Travel Agent.");
          return {
            ok: false,
            message: err.message,
          };
        });
    },
    getCompanyNamesList: ({ commit, dispatch }, payload) => {
      let fail = (msg) => commit("failure", msg);
      return dispatch(
        "apiCall",
        {
          method: "get",
          params: payload,
          url: constants.ALL_COMPANY_NAMES,
        },
        { root: true }
      )
        .then((data) => {
          if (data.ok) {
            return {
              ok: true,
              list: data.data,
            };
          } else {
            fail(data.message || "Failed to get Companies Names List");
            return {
              ok: false,
              data: {},
            };
          }
        })
        .catch((err) => {
          console.log("Yo ", err);
          fail(err.toString() || "Failed to get Companies Names List");
          return { ok: false, data: {} };
        });
    },
    getTravelAgentEmployeeNames: ({ commit, dispatch }, payload) => {
      let fail = (msg) => commit("failure", msg);
      return dispatch(
        "apiCall",
        {
          method: "get",
          params: payload,
          url: constants.TRAVEL_AGENT_EMPLOYEES_NAMES,
        },
        { root: true }
      )
        .then((data) => {
          if (data.ok) {
            return {
              ok: true,
              list: data.data,
            };
          } else {
            fail(data.message || "Failed to load employee names List");
            return {
              ok: false,
              list: [],
            };
          }
        })
        .catch((err) => {
          console.log("Yo ", err);
          fail(err.toString() || "Failed to employee names List");
          return { ok: false, list: [] };
        });
    },
    mergeTravelAgents: ({ commit, dispatch }, payload) => {
      let fail = (msg) => commit("failure", msg);
      let url = "";
      switch (payload.type) {
        case "company":
          url = `${constants.MERGE_TRAVEL_AGENTS}?fromTravelAgentId=${payload.moveFromId}&toTravelAgentId=${payload.moveToId}`;
          break;
        case "address":
          url = `${constants.MERGE_TRAVEL_AGENTS_ADDRESS}?fromCompanyAddressId=${payload.moveFromId}&toCompanyAddressId=${payload.moveToId}`;
          break;
        case "employee":
          url = `${constants.MERGE_TRAVEL_AGENTS_EMPLOYEE}?fromTravelAgentEmployeeId=${payload.moveFromId}&toTravelAgentEmployeeId=${payload.moveToId}`;
          break;
      }
      return dispatch(
        "apiCall",
        {
          method: "post",
          url: url,
          data: payload.mergedData,
        },
        { root: true }
      )
        .then((data) => {
          if (data.ok) {
            commit(
              "openSnackbar",
              { text: "Merged Successfully" },
              { root: true }
            );
            return {
              ok: true,
              message: data.message,
            };
          } else {
            fail(data.message || "Failed to merge agents");
            commit(
              "openSnackbar",
              { text: "Failed To Merge" },
              { root: true }
            );
            return {
              ok: false,
              list: [],
            };
          }
        })
        .catch((err) => {
          fail(err.toString() || "Failed to merge agents");
          return { ok: false, list: [] };
        });
    },
    downloadDuplicateCompaniesData: ({ commit, dispatch }, payload) => {
      let fail = (msg) => commit("failure", msg);
      return dispatch(
        "fileDownload_API_Call",
        {
          method: "get",
          params: {},
          params: payload,
          url: constants.DOWNLOAD_DUPLCATE_COMPANIES_DATA,
          responseType: "blob",
        },
        { root: true }
      )
        .then(({ data, response }) => {
          if (response.status === 200) {
            commit(
              "openSnackbar",
              { text: "Starting Download" },
              { root: true }
            );
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "DuplicateCompaniesData.xlsx");
            document.body.appendChild(link);
            link.click();
            return;
          } else {
            commit(
              "openSnackbar",
              { text: "Could not start download" },
              { root: true }
            );
            fail(data.message || "Failed to start download");
            return;
          }
        })
        .catch((err) => {
          console.log("Yo ", err);
          commit(
            "openSnackbar",
            { text: "Could not start download" },
            { root: true }
          );
          fail(err.toString() || "Failed to Download Core Data File");
        });
    },
  },
  getters: {
    storeAddressList: (state) => state.addressList,
    storeEmployeesList: (state) => state.employeesList,
    storeStatesList: (state) => state.statesList,
    showMergeModal: (state) => state.showMergeModal,
    dataForMoveFrom: (state) => state.dataForMoveFrom,
    dataForMoveTo: (state) => state.dataForMoveTo,
  },
};
