import constants from "@/api";
import helpers from "../../components/helpers";

const initialState = () => ({});
export default {
	namespaced: true,
	state: initialState(),
	mutations: {
		failure: (s, msg) => {
			console.log("[failure] ", msg);
		},
		resetState(state) {
			const initial = initialState();
			Object.keys(initial).forEach((key) => {
				state[key] = initial[key];
			});
		},
	},
	actions: {
		getDSR: ({ commit, dispatch }, payload) => {
			let URLparam = payload.reportType;
			delete payload.reportType;
			let fail = (msg) => commit("failure", msg);
			return dispatch(
				"apiCall",
				{
					method: "get",
					params: payload,
					url: constants.DSR + "dsr/" + URLparam + "/",
				},
				{ root: true }
			)
				.then((data) => {
					if (data.ok) {
						return {
							ok: true,
							totalCount: data.totalCount,
							fetchCount: data.fetchCount,
							list: data.data,
						};
					} else {
						fail(data.message || "Failed to load All DSR List");
						return {
							ok: false,
							totalCount: data.totalCount,
							fetchCount: 0,
							list: [],
						};
					}
				})
				.catch((err) => {
					console.log("Yo ", err);
					fail(err.toString() || "Failed to All DSR List");
					return { ok: false, totalCount: 0, fetchCount: 0, list: [] };
				});
		},
		addDSR: ({ commit, dispatch }, payload) => {
			let fail = (msg) => commit("failure", msg);
			return dispatch(
				"apiCall",
				{
					method: "post",
					data: payload,
					url: constants.DSR,
				},
				{ root: true }
			)
				.then((data) => {
					if (!data.ok) fail(data.message || "Failed to add DSR Entry");
					return data;
				})
				.catch((err) => {
					fail(err.toString() || "Failed to add DSR Entry");
					return {
						ok: false,
						message: err.message,
					};
				});
		},
		editDSR: ({ commit, dispatch }, payload) => {
			let fail = (msg) => commit("failure", msg);
			return dispatch(
				"apiCall",
				{
					method: "put",
					data: payload,
					url: constants.DSR,
				},
				{ root: true }
			)
				.then((data) => {
					if (!data.ok) fail(data.message || "Failed to edit DSR entry");
					return data;
				})
				.catch((err) => {
					fail(err.toString() || "Failed to edit DSR Entry");
					return {
						ok: false,
						message: err.message,
					};
				});
		},
		deleteDSR: ({ commit, dispatch }, payload) => {
			let fail = (msg) => commit("failure", msg);
			return dispatch(
				"apiCall",
				{
					method: "delete",
					data: payload,
					url: constants.DSR,
				},
				{ root: true }
			)
				.then((data) => {
					if (!data.ok) fail(data.message || "Failed to Delete DSR Entry");
					return data;
				})
				.catch((err) => {
					fail(err.toString() || "Failed to Delete DSR Entry");
					return {
						ok: false,
						message: err.message,
					};
				});
		},
		downloadDSR: ({ commit, dispatch }, payload) => {
			let fail = (msg) => commit("failure", msg);

			let fileName =
				"Sales from " +
				helpers.getFormattedDate(payload.filter.date_from, "YYYY-MM-DD") +
				" to " +
				helpers.getFormattedDate(payload.filter.date_to, "YYYY-MM-DD");
				console.log(payload)
			let URLparam =payload.dsrType == "my_dsr" ? `${payload.dsrType}?filter={"mortal_id":"${payload.filter.mortal_id}","date_from":"${payload.filter.date_from}","date_to":"${payload.filter.date_to}"}` : `${payload.dsrType}?filter={"date_from":"${payload.filter.date_from}","date_to":"${payload.filter.date_to}"}`

			delete payload.callType;
			// delete payload.date_from;
			// delete payload.date_to;

			return dispatch(
				"fileDownload_API_Call",
				{
					method: "get",
					url: constants.DOWNLOAD_DSR_RECORDS + URLparam ,
					responseType: "blob",
				},
				{ root: true }
			)
				.then(({ data, response }) => {
					if (response.status === 200) {
						commit("openSnackbar", { text: "Starting Download" }, { root: true });
						const url = window.URL.createObjectURL(new Blob([data]));
						const link = document.createElement("a");
						link.href = url;
						link.setAttribute("download", fileName + ".xlsx");
						document.body.appendChild(link);
						link.click();
						return;
					} else {
						commit("openSnackbar", { text: "Could not start download" }, { root: true });
						fail(data.message || "Failed to start download");
						return;
					}
				})
				.catch((err) => {
					console.log("Yo ", err);
					commit("openSnackbar", { text: "Could not start download" }, { root: true });
					fail(err.toString() || "Failed to Download Sales Call File");
				});
		},
	},
	getters: {},
};
