var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-navigation-drawer',{staticClass:"navigationDrawerWrapper",attrs:{"color":"primary","hide-overlay":"","fixed":"","app":""},model:{value:(_vm.navigationToggle),callback:function ($$v) {_vm.navigationToggle=$$v},expression:"navigationToggle"}},[_c('div',{staticClass:"logo-wrapper"},[_c('img',{attrs:{"src":require("./assets/GD Logo V2.png"),"alt":""}})]),_c('v-list',{attrs:{"id":"navigation-list","flat":""}},[_vm._l((_vm.routeItems),function(navItem,index){return [_c('v-list-item',{key:navItem.route + '' + index,class:{
						'active-list-item': _vm.currentRoute == navItem.route ? true : false,
					},attrs:{"id":"basic-list-item"},on:{"click":function($event){return _vm.openPortal(navItem)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{staticClass:"list-title",class:{
									'selected-route': _vm.currentRoute == navItem.title ? true : false,
								}},[_vm._v(_vm._s(navItem.title))])])],1)],1)]})],2)],1),_c('v-app-bar',{staticClass:"toolbarWrapper",attrs:{"color":"primary","app":"","fixed":""}},[_c('v-btn',{attrs:{"icon":"","x-large":""},on:{"click":_vm.toggleNav}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-menu")])],1),_c('v-toolbar-title',[_c('span',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(_vm.title))])]),_c('v-spacer'),_c('div',{staticClass:"toolbar-version"},[_c('v-chip',{attrs:{"color":"white"}},[_vm._v(" v "+_vm._s(_vm.version)+" ")])],1),_c('v-btn',{attrs:{"large":"","icon":""},on:{"click":function($event){return _vm.openPortal(_vm.settingsRoute)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-cog")])],1),_c('v-btn',{attrs:{"large":"","icon":""},on:{"click":_vm.logoutUser}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-logout")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
								var on = ref.on;
								var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"toolbar-profile",attrs:{"size":"40"}},'v-avatar',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getProfileName())+" ")])]}}])},[(_vm.userData)?_c('span',[_vm._v(_vm._s(_vm.userData.usr_data ? _vm.userData.usr_data.name : "")+" ("+_vm._s(_vm.userData.username ? _vm.userData.username : "")+")")]):_vm._e()])],1),_c('v-main',[_c('router-view')],1),_c('v-snackbar',{attrs:{"multi-line":"","light":"","top":"","right":"","timeout":_vm.snackbarTime},model:{value:(_vm.localSnackbarState),callback:function ($$v) {_vm.localSnackbarState=$$v},expression:"localSnackbarState"}},[_c('div',{staticClass:"snackbarComponentWrapper"},[_c('div',{staticClass:"content"},[_vm._v(_vm._s(_vm.snackbarText))]),_c('div',{staticClass:"action-button"},[_c('v-btn',{attrs:{"small":"","fab":"","color":"accent","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.closeSnackbar($event)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)])]),_c('v-overlay',{staticClass:"app-loader",attrs:{"value":_vm.loaderDialog,"z-index":300}},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }