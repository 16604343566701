import constants from "@/api";

const initialState = () => ({
	affiliateAddressList: [],
	affiliateEmployeesList: [],
	affiliateStatesList: [],
})


export default {
	namespaced: true,
	state: initialState(),
	mutations: {
		failure: (s, msg) => {
			console.log("[failure] ", msg);
		},
		resetState(state) {
			const initial = initialState();
			Object.keys(initial).forEach((key) => {
				state[key] = initial[key];
			});
		},
		setAffiliateAddressList(state, data) {
			state.affiliateAddressList = data;
		},
		setAffiliateEmployeeList(state, data) {
			state.affiliateEmployeesList = data;
		},
		setAffiliateStateList(state, data) {
			state.affiliateStatesList = data;
		},
		
	},
	actions: {
		getChangelogsList: ({ commit, dispatch }, payload) => {
			let fail = (msg) => commit("failure", msg);
			return dispatch(
				"apiCall",
				{
					method: "get",
					params: payload,
					url: constants.CHANGELOGS,
				},
				{ root: true }
			)
				.then((data) => {
					if (data.ok) {
						return {
							ok: true,
							list: data.data,
							fetchCount: data.fetchCount,
							totalCount: data.totalCount,
						};
					} else {
						fail(data.message || "Failed to load ChangeLogs List");
						return {
							ok: false,
							list: [],
						};
					}
				})
				.catch((err) => {
					console.log("Yo ", err);
					fail(err.toString() || "Failed to load ChangeLogs List");
					return { ok: false, totalCount: 0, fetchCount: 0, list: [] };
				});
		},
		getStatesList: ({ commit, dispatch }, payload) => {
			let fail = (msg) => commit("failure", msg);
			return dispatch(
				"apiCall",
				{
					method: "get",
					params: payload,
					url: constants.ALL_STATES,
				},
				{ root: true }
			)
				.then((data) => {
					console.log(data);
					if (data.ok) {
						commit("setAffiliateStateList", data.data);
						return {
							ok: true,
							list: data.data,
							fetchCount: data.fetchCount,
							totalCount: data.totalCount,
						};
					} else {
						fail(data.message || "Failed to load States List");
						return {
							ok: false,
							list: [],
						};
					}
				})
				.catch((err) => {
					console.log("Yo ", err);
					fail(err.toString() || "Failed to load States List");
					return { ok: false, totalCount: 0, fetchCount: 0, list: [] };
				});
		},
		getAffiliatesList: ({ commit, dispatch }, payload) => {
			let fail = (msg) => commit("failure", msg);
			return dispatch(
				"apiCall",
				{
					method: "get",
					params: payload,
					url: constants.AFFILIATES,
				},
				{ root: true }
			)
				.then((data) => {
					if (data.ok) {
						return {
							ok: true,
							totalCount: data.totalCount,
							fetchCount: data.fetchCount,
							list: data.data,
						};
					} else {
						fail(data.message || "Failed to load Affiliate List");
						return {
							ok: false,
							totalCount: data.totalCount,
							fetchCount: 0,
							list: [],
						};
					}
				})
				.catch((err) => {
					console.log("Yo ", err);
					fail(err.toString() || "Failed to load Affiliate List");
					return { ok: false, totalCount: 0, fetchCount: 0, list: [] };
				});
		},
		addAffiliate: ({ commit, dispatch }, payload) => {
			let fail = (msg) => commit("failure", msg);
			return dispatch(
				"apiCall",
				{
					method: "post",
					data: payload,
					url: constants.AFFILIATES,
				},
				{ root: true }
			)
				.then((data) => {
					if (!data.ok) fail(data.message || "Failed to add Affiliate");
					return data;
				})
				.catch((err) => {
					fail(err.toString() || "Failed to add Affiliate");
					return {
						ok: false,
						message: err.message,
					};
				});
		},
		editAffiliate: ({ commit, dispatch }, payload) => {
			let fail = (msg) => commit("failure", msg);
			return dispatch(
				"apiCall",
				{
					method: "put",
					data: payload,
					url: constants.AFFILIATES,
				},
				{ root: true }
			)
				.then((data) => {
					if (!data.ok) fail(data.message || "Failed to edit Affiliate");
					return data;
				})
				.catch((err) => {
					fail(err.toString() || "Failed to edit Affiliate");
					return {
						ok: false,
						message: err.message,
					};
				});
		},
		deleteAffiliate: ({ commit, dispatch }, payload) => {
		let fail = (msg) => commit("failure", msg);
		return dispatch(
			"apiCall",
			{
				method: "delete",
				data: payload,
				url: constants.AFFILIATES,
			},
			{ root: true }
		)
			.then((data) => {
				if (!data.ok) fail(data.message || "Failed to Delete Affiliate.");
				return data;
			})
			.catch((err) => {
				fail(err.toString() || "Failed to Delete Affiliate.");
				return {
					ok: false,
					message: err.message,
				};
			});
		},
		getAffiliateAddresses: ({ commit, dispatch }, payload) => {
			let fail = (msg) => commit("failure", msg);
			return dispatch(
				"apiCall",
				{
					method: "get",
					params: payload,
					url: constants.AFFILIATES_ADDRESS,
				},
				{ root: true }
			)
				.then((data) => {
					if (data.ok) {
						return {
							ok: true,
							totalCount: data.totalCount,
							fetchCount: data.fetchCount,
							list: data.data,
						};
					} else {
						fail(data.message || "Failed to Load Affiliate Address List");
						return {
							ok: false,
							totalCount: data.totalCount,
							fetchCount: 0,
							list: [],
						};
					}
				})
				.catch((err) => {
					console.log("Yo ", err);
					fail(err.toString() || "Failed to Load Affiliate Address List");
					return { ok: false, totalCount: 0, fetchCount: 0, list: [] };
				});
		},
		getAffiliateCitiesList: ({ commit, dispatch }, payload) => {
			let fail = (msg) => commit("failure", msg);
			return dispatch(
				"apiCall",
				{
					method: "get",
					params: payload,
					url: constants. AFFILIATE_CITIES,
				},
				{ root: true }
			)
				.then((data) => {
					if (data.ok) {
						return {
							ok: true,
							list: data.data,
							fetchCount: data.fetchCount,
							totalCount: data.totalCount,
						};
					} else {
						fail(data.message || "Failed to get Agent Cities List");
						return {
							ok: false,
							list: [],
							fetchCount: 0,
							totalCount: 0,
						};
					}
				})
				.catch((err) => {
					console.log("Yo ", err);
					fail(err.toString() || "Failed to get Agent Cities List");
					return { ok: false, totalCount: 0, fetchCount: 0, list: [] };
				});
		},
		addAffiliateAddress: ({ commit, dispatch }, payload) => {
			let fail = (msg) => commit("failure", msg);
			return dispatch(
				"apiCall",
				{
					method: "post",
					data: payload,
					url: constants.AFFILIATES_ADDRESS,
				},
				{ root: true }
			)
				.then((data) => {
					if (!data.ok) fail(data.message || "Failed to add Affiliate Address");
					return data;
				})
				.catch((err) => {
					fail(err.toString() || "Failed to add Affiliate Address");
					return {
						ok: false,
						message: err.message,
					};
				});
		},
		editAffiliateAddress: ({ commit, dispatch }, payload) => {
			let fail = (msg) => commit("failure", msg);
			return dispatch(
				"apiCall",
				{
					method: "put",
					data: payload,
					url: constants.AFFILIATES_ADDRESS,
				},
				{ root: true }
			)
				.then((data) => {
					if (!data.ok) fail(data.message || "Failed to edit Affiliate Address");
					return data;
				})
				.catch((err) => {
					fail(err.toString() || "Failed to edit Affiliate Address");
					return {
						ok: false,
						message: err.message,
					};
				});
		},
		checkAffiliateEmployeeDetail: ({ commit, dispatch }, payload) => {
			let fail = (msg) => commit("failure", msg);
			return dispatch(
				"apiCall",
				{
					method: "get",
					params: payload,
					url: constants.DUPLICATE_AFFILIATE_EMPLOYEE_WARNING,
				},
				{ root: true }
			)
				.then((data) => {
					if (data.ok) {
						return {
							ok: true,
							data: data.data,
						};
					} else {
						fail(data.message || "Failed to get Affiliate Employee Details");
						return {
							ok: false,
							data: {},
						};
					}
				})
				.catch((err) => {
					console.log("Yo ", err);
					fail(err.toString() || "Failed to get Affiliate Employee Details");
					return { ok: false, data: {} };
				});
		},
		getAffiliateEmployeeInformation: ({ commit, dispatch }, payload) => {
			let fail = (msg) => commit("failure", msg);
			return dispatch(
				"apiCall",
				{
					method: "get",
					params: payload,
					url: constants.AFFILIATE_EMPLOYEE_DETAILS,
				},
				{ root: true }
			)
				.then((data) => {
					if (data.ok) {
						return {
							ok: true,
							list: data.data,
							fetchCount: data.fetchCount,
							totalCount: data.totalCount,
						};
					} else {
						fail(data.message || "Failed to get Affiliate Employee Information List");
						return {
							ok: false,
							list: [],
							fetchCount: 0,
							totalCount: 0,
						};
					}
				})
				.catch((err) => {
					console.log("Yo ", err);
					fail(err.toString() || "Failed to get Affiliate Employee Information List");
					return { ok: false, totalCount: 0, fetchCount: 0, list: [] };
				});
		},
		getAffiliateEmployeeList: ({ commit, dispatch }, payload) => {
			let fail = (msg) => commit("failure", msg);
			return dispatch(
				"apiCall",
				{
					method: "get",
					params: payload,
					url: constants.AFFILIATES_EMPLOYEES,
				},
				{ root: true }
			)
				.then((data) => {
					if (data.ok) {
						return {
							ok: true,
							totalCount: data.totalCount,
							fetchCount: data.fetchCount,
							list: data.data,
						};
					} else {
						fail(data.message || "Failed to Load Affiliate Employee List");
						return {
							ok: false,
							totalCount: data.totalCount,
							fetchCount: 0,
							list: [],
						};
					}
				})
				.catch((err) => {
					console.log("Yo ", err);
					fail(err.toString() || "Failed to Load Affiliate Employee List");
					return { ok: false, totalCount: 0, fetchCount: 0, list: [] };
				});
		},
		addAffiliateEmployee: ({ commit, dispatch }, payload) => {
			let fail = (msg) => commit("failure", msg);
			return dispatch(
				"apiCall",
				{
					method: "post",
					data: payload,
					url: constants.AFFILIATES_EMPLOYEES,
				},
				{ root: true }
			)
				.then((data) => {
					if (!data.ok) fail(data.message || "Failed to add Affiliate Employee");
					return data;
				})
				.catch((err) => {
					fail(err.toString() || "Failed to add Affiliate Employee");
					return {
						ok: false,
						message: err.message,
					};
				});
		},
		editAffiliateEmployee: ({ commit, dispatch }, payload) => {
			let fail = (msg) => commit("failure", msg);
			return dispatch(
				"apiCall",
				{
					method: "put",
					data: payload,
					url: constants.AFFILIATES_EMPLOYEES,
				},
				{ root: true }
			)
				.then((data) => {
					if (!data.ok) fail(data.message || "Failed to edit Affiliate Employee");
					return data;
				})
				.catch((err) => {
					fail(err.toString() || "Failed to edit Affiliate Employee");
					return {
						ok: false,
						message: err.message,
					};
				});
		},
		spawnProcess: ({ commit, dispatch }) => {
			let fail = (msg) => commit("failure", msg);
			return dispatch(
				"apiCall",
				{
					method: "get",
					data: {},
					url: constants. SPAWN_PROCESS_AFFILIATES,
				},
				{ root: true }
			)
				.then((data) => {
					if (data.ok) {
						return data;
					} else {
						fail(data.message || "Failed spawn process");
						return { ok: false };
					}
				})
				.catch((err) => {
					console.log("Yo ", err);
					fail(err.toString() || "Failed spawn process");
					return { ok: false };
				});
		},
		deleteSpawnProcess: ({ commit, dispatch }, payload) => {
			let fail = (msg) => commit("failure", msg);
			return dispatch(
				"apiCall",
				{
					method: "post",
					data: payload,
					url: constants.DELETE_SPAWN_PROCESS_AFFILIATES,
				},
				{ root: true }
			)
				.then((data) => {
					if (!data.ok) fail(data.message || "Failed to delete Spawn Process");
					return data;
				})
				.catch((err) => {
					fail(err.toString() || "Failed to delete Spawn Process");
					return {
						ok: false,
						message: err.message,
					};
				});
		},
		getUploadLogs: ({ commit, dispatch }, payload) => {
			let fail = (msg) => commit("failure", msg);
			return dispatch(
				"apiCall",
				{
					method: "get",
					params: payload,
					url: constants.BULK_UPLOAD_LOGS,
				},
				{ root: true }
			)
			.then((data) => {
				if (data.ok) {
					return {
						ok: true,
						list: data.data,
						fetchCount: data.fetchCount,
						totalCount: data.totalCount,
					};
				} else {
					fail(data.message || "Failed to load upload Logs List");
					return {
						ok: false,
						totalCount: 0,
						fetchCount: 0,
						list: [],
					};
				}
			})
			.catch((err) => {
				console.log("Yo ", err);
				fail(err.toString() || "Failed to load upload Logs List");
				return { ok: false, totalCount: 0, fetchCount: 0, list: [] };
			});
		},
		getInputFileURL: ({ commit, dispatch }, payload) => {
			let fail = (msg) => commit("failure", msg);
			return dispatch(
				"apiCall",
				{
					method: "get",
					params: payload,
					url: constants.BULK_UPLOAD_INPUT_FILE,
				},
				{ root: true }
			)
				.then((data) => {
				if (data.ok) {
					return {
						ok: true,
						url: data.url,
					};
				} else {
					fail(data.message || "Failed to load upload Logs List");
					return {
						ok: false,
						url: "",
					};
				}
			})
			.catch((err) => {
				console.log("Yo ", err);
				fail(err.toString() || "Failed to load upload Logs List");
				return { ok: false, url: "" };
			});
		},
		downloadErrorFile: ({ commit, dispatch }, payload) => {
			let fail = (msg) => commit("failure", msg);
			return dispatch(
				"fileDownload_API_Call",
				{
					method: "get",
					params: {},
					params: payload,
					url: constants.BULK_UPLOAD_ERROR_FILE,
					responseType: "blob",
				},
				{ root: true }
			)
				.then(({ data, response }) => {
					if (response.status === 200) {
						commit("openSnackbar", { text: "Starting Download" }, { root: true });
						const url = window.URL.createObjectURL(new Blob([data]));
						const link = document.createElement("a");
						link.href = url;
						link.setAttribute("download", "Errors.xlsx"); //or any other extension
						document.body.appendChild(link);
						link.click();
						return;
					} else {
						commit("openSnackbar", { text: "Could not start download" }, { root: true });
						fail(data.message || "Failed to start download");
						return;
					}
				})
				.catch((err) => {
					console.log("Yo ", err);
					commit("openSnackbar", { text: "Could not start download" }, { root: true });
					fail(err.toString() || "Failed to Download Core Data File");
				});
		},
		uploadAffiliates: ({ commit, dispatch }, payload) => {
			let fail = (msg) => commit("failure", msg);
			return dispatch(
				"apiCallWithHeaderConfig",
				{
					partConfig: {
						method: "post",
						data: payload,
						url: constants.UPLOAD_AFFILIATES,
					},
					headerConfig: {
						"Content-Type": "multipart/form-data",
					},
				},
				{ root: true }
			)
				.then((data) => {
					if (!data.ok) fail(data.message || "Failed to upload AFFILIATES file");
					return data;
				})
				.catch((err) => {
					fail(err.toString() || "Failed to upload AFFILIATES file");
					return {
						ok: false,
						message: err.message,
					};
				});
		},

	},
	getters: {
		storeAffiliateAddressList: (state) => state.addressList,
		storeAffiliateEmployeesList: (state) => state.employeesList,
		storeAffiliateStatesList: (state) => state.statesList,
	},
}